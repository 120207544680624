html, body {
  background-color: #0E0C0A;
  color: white;
}

.text-block {
  max-width: 800px;
  margin: 0 auto;
}

.text-block--spacer {
  margin-top: 20px;
}

.text-block--spacer-large {
  margin-top: 60px;
}

.sth-banner {
  width: 100%;
}

.main-image {
  display: block;
  width: 100%;
  margin-top: 30px;
}